/* Arrow.css */

/* Basic arrow styles for v1 variant */
.arrow-btn {
    position: absolute;
    top: 10px; /* Adjust according to desired position */
    right: -5px; /* Adjust according to desired position */
    width: 35px;
    height: 35px;
    background-color: #666666;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    transition: transform 0.4s ease-in-out, background-color 0.3s ease;
    z-index: 10;
    overflow: hidden;
}

.arrow-btn:before {
    content: "";
    position: absolute;
    background-color: #fff;
    width: 3px;
    height: 12px;
    top: 60%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) rotate(135deg);
}

.arrow-btn:after {
    content: "";
    position: absolute;
    background-color: #fff;
    width: 3px;
    height: 12px;
    top: 40%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) rotate(45deg);
}

.arrow-btn:hover {
    background-color: #999;
}

.arrow-btn.drawer-open:before {
    transform: translateY(-50%) translateX(-50%) rotate(-135deg);
}

.arrow-btn.drawer-open:after {
    transform: translateY(-50%) translateX(-50%) rotate(-45deg);
}

/* Filing menu arrow styles (migrated from filings/components/menu/Arrow.css) */
.arrow-btn2 {
    background: none;
    position: absolute;
    top: 10px;
    right: -12px;
    border: none;
    cursor: pointer;
    padding: 4px;
    transition: all 0.3s ease;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
}

.arrow-btn2:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.arrow-btn2:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.arrow-btn2 svg {
    transition: transform 0.3s ease;
}

.arrow-btn2.open svg {
    transform: rotate(180deg);
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
    .arrow-btn {
        background-color: #444444;
    }
    
    .arrow-btn:hover {
        background-color: #666666;
    }
    
    .arrow-btn2:hover {
        background-color: rgba(255, 255, 255, 0.05);
    }
}

/* When dark mode is explicitly enabled with a class */
html.dark .arrow-btn {
    background-color: #444444;
}

html.dark .arrow-btn:hover {
    background-color: #666666;
}

html.dark .arrow-btn2:hover {
    background-color: rgba(255, 255, 255, 0.05);
}