/* Styles for transcript iframe container */
.iframe-container {
    position: relative;
    width: calc(100%);
    height: 100%;
    overflow: auto;
    background-color: #FFF;
}

.dark .iframe-container {
    background-color: #1e1e1e; /* VSCode-style dark gray */
}

.iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.iframe-container.menu-hidden {
    width: calc(100%);
}

/* Specific class for dark mode to help with JS detection */
.iframe-container.dark-mode {
    background-color: #1e1e1e;
}

/* Apply dark mode styling when dark mode is active */
.iframe-container.dark-mode iframe.dark-iframe {
    /* We're using direct CSS injection instead of filters */
    /* filter: invert(92%) hue-rotate(180deg); */
}

/* Exception for images within iframe - used if filter approach is employed */
.iframe-container.dark-mode iframe.dark-iframe img {
    /* filter: invert(100%) hue-rotate(180deg); */
}