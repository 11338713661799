/* Standard CSS for .iframe-container */
.iframe-container {
    position: relative;
    width: calc(100%); /* Adjusted width when the menu is visible. Replace 250px with your actual menu width. */
    height: 100%;
    overflow: auto;
    background-color: #FFF;
}

/* Dark mode styles */
.dark .iframe-container {
    background-color: #FFF; /* Keep background white even in dark mode */
}

.iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    background-color: white !important;
}

.iframe-container iframe.white-background {
    background-color: white !important;
}

.iframe-container.menu-hidden {
    width: calc(100%); /* Full width when the menu is hidden */
}

.popup-menu {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 4px 8px;
    z-index: 10;
}

.popup-menu div {
    cursor: pointer;
    padding: 2px 4px;
}

.popup-menu div:hover {
    background-color: #f1f1f1;
}