/* Dark mode styles for tables */
.clear-style {
    color: inherit;
}

.clear-style table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1rem;
}

.clear-style th,
.clear-style td {
    border: 1px solid #e5e7eb;
    padding: 0.75rem;
    text-align: left;
}

:root[data-theme='dark'] .clear-style th,
:root[data-theme='dark'] .clear-style td {
    border-color: #374151;
}

.clear-style th {
    background-color: #f3f4f6;
    font-weight: 600;
}

:root[data-theme='dark'] .clear-style th {
    background-color: #374151;
}

.clear-style tr:nth-child(even) {
    background-color: #f9fafb;
}

:root[data-theme='dark'] .clear-style tr:nth-child(even) {
    background-color: #1f2937;
}

:root[data-theme='dark'] .clear-style tr:nth-child(odd) {
    background-color: #111827;
}

.clear-style tr:hover {
    background-color: #f3f4f6;
}

:root[data-theme='dark'] .clear-style tr:hover {
    background-color: #374151;
}